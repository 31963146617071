<template>
  <div class="analytics-usage-measures">
    <div class="row">
      <div class="col-sm-12">
        <owl-usage-measures-bar-chart
          class="tw-mb-12"
          :is-loading="measuresLoading"
          :series="measuresSeries"
          :start="start"
          :end="end"
          :graph-interval="graphInterval"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { OwlUsageMeasuresBarChart } from 'owl-components'
import {
  FETCH_MEASURES
} from '@/store/modules/analytics/usage/constants.js'

export default {
  name: 'AnalyticsUsageMeasures',
  components: {
    OwlUsageMeasuresBarChart
  },
  computed: {
    ...mapGetters({
      globalParams: 'getAnalyticsUsageGlobalParams',
      measuresLoading: 'getAnalyticsUsageMeasuresLoadingState',
      measuresParams: 'getAnalyticsUsageMeasuresParams',
      measuresSeries: 'getAnalyticsUsageMeasuresSeries'
    }),
    chartOptions () {
      return {}
    },
    start () {
      return this.measuresParams.criteria.due_date.greater_than
    },
    end () {
      return this.measuresParams.criteria.due_date.less_than
    },
    graphInterval () {
      return this.measuresParams.criteria.graph_interval
    }
  },
  watch: {
    globalParams: {
      deep: true,
      handler () {
        this.getMeasuresData()
      }
    }
  },
  created () {
    this.getMeasuresData()
  },
  methods: {
    getMeasuresData () {
      this.$store.dispatch(FETCH_MEASURES)
    }
  }
}
</script>
